import React from 'react';
import { RouteProps } from 'react-router-dom';
import { dashboardPagesMenu, evoHuntPages } from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';

const headers: RouteProps[] = [
	{
		path: evoHuntPages.evohunt.subMenu.homeCandidato.subMenu.applicationsPage.path,
		element: <DashboardHeader />,
	},
	{ path: dashboardPagesMenu.dashboard.path, element: <DashboardHeader /> },
	{ path: dashboardPagesMenu.companyPreview.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.login.path, element: <DashboardHeader /> },
	{
		path: evoHuntPages.evohunt.subMenu.candidatoIdeatoreSignup.path,
		element: <DashboardHeader />,
	},
	{ path: evoHuntPages.evohunt.subMenu.aziendaStartupSignup.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.userProfile.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.aziendaProfileHome.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.descrizioneAzienda.path, element: <DashboardHeader /> },
	{
		path: evoHuntPages.evohunt.subMenu.descrizioneAziendaDetail.path,
		element: <DashboardHeader />,
	},
	{ path: evoHuntPages.evohunt.subMenu.datiReferente.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.CuriositaPage.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.linkAziendali.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.FaqPage.path, element: <DashboardHeader /> },
	{
		path: evoHuntPages.evohunt.subMenu.dettaglioCandidatoInRicerca.path,
		element: <DashboardHeader />,
	},
	{ path: evoHuntPages.evohunt.subMenu.scelte.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.studies.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.workingExperiences.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.languages.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.payment.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.widget.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.statistics.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.planner.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.competenze.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.certifications.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.courses.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.jobsLookingFor.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.insertUpdate.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.testForm.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.savedTest.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.patenti.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.hobbySports.path, element: <DashboardHeader /> },
	{
		path: evoHuntPages.evohunt.subMenu.hobbySports.subMenu.hobbies.path,
		element: <DashboardHeader />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.hobbySports.subMenu.sports.path,
		element: <DashboardHeader />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.certificationsOpenBadge.path,
		element: <DashboardHeader />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.certificationsOpenBadge.subMenu.certifications.path,
		element: <DashboardHeader />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.certificationsOpenBadge.subMenu.openBadges.path,
		element: <DashboardHeader />,
	},
	{ path: evoHuntPages.evohunt.subMenu.motivations.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.ideas.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.ideaDetail.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.viewCompanyOffer.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.viewStartupOffer.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.viewIdeatorOffer.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.statsArea.path, element: <DashboardHeader /> },
	{
		path: evoHuntPages.evohunt.subMenu.candidatureTestVideoEducational.subMenu.testVideo.subMenu
			.videoArea.path,
		element: <DashboardHeader />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.candidatureTestVideoEducational.subMenu.testVideo.subMenu
			.testArea.path,
		element: <DashboardHeader />,
	},
	{ path: evoHuntPages.evohunt.subMenu.cVAndIDArea.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.starteamArea.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.creativaArea.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.coursesWithDetail.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.courseDetail.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.workTrainingArea.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.setSteps.path, element: <DashboardHeader /> },
	{
		path: evoHuntPages.evohunt.subMenu.ricerca.subMenu.ricercaConArchetipo.path,
		element: <DashboardHeader />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ricerca.subMenu.ricercaPersonalizzata.path,
		element: <DashboardHeader />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.descrizioneCandidatoTabbed.path,
		element: <DashboardHeader />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.descriptionProfileCandidatoDetail.path,
		element: <DashboardHeader />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.descriptionProfileCandidato.path,
		element: <DashboardHeader />,
	},
	{ path: evoHuntPages.evohunt.subMenu.homeCandidato.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.homeAzienda.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.homeIdeatore.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.userProfile.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.homeStartup.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.userProfileStartup.path, element: <DashboardHeader /> },
	{
		path: evoHuntPages.evohunt.subMenu.candidatureTestVideoEducational.subMenu.testVideo.path,
		element: <DashboardHeader />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.candidatureTestVideoEducational.subMenu.educational.path,
		element: <DashboardHeader />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.candidatureTestVideoEducational.path,
		element: <DashboardHeader />,
	},
	{ path: evoHuntPages.evohunt.subMenu.softSkill.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.payment.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.ricercheArchiviate.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.ricercheTerminate.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.createTeam.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.richiediVideoAzienda.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.richiediTestAzienda.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.settings.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.changePassword.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.savedTests.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.companyTeamPage.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.companyTeamDetailPage.path, element: <DashboardHeader /> },
	{
		path: evoHuntPages.evohunt.subMenu.companyTeamSkillDetailPage.path,
		element: <DashboardHeader />,
	},
	{ path: evoHuntPages.evohunt.subMenu.notifiche.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.queryCandidateList.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.detailOfferTabbed.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.queryTestVideo.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.queryTestVideoTabbed.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.queryTestDetail.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.tipsTabbed.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.volounteers.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.ricerca.path, element: <DashboardHeader /> },
	{
		path: `/${evoHuntPages.evohunt.subMenu.testVideoTabbed.path}/:tab`,
		element: <DashboardHeader />,
	},
	{ path: evoHuntPages.evohunt.subMenu.viewTestPage.path, element: <DashboardHeader /> },
	{
		path: evoHuntPages.evohunt.subMenu.viewCompletedTestPage.path,
		element: <DashboardHeader />,
	},
	{ path: evoHuntPages.evohunt.subMenu.messaggi.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.chatSupporto.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.iMieiTeam.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.companyTestVideo.path, element: <DashboardHeader /> },
	{
		path: evoHuntPages.evohunt.subMenu.companyTeamPersonalisedSearch.path,
		element: <DashboardHeader />,
	},
	{
		path: `/${evoHuntPages.evohunt.subMenu.companyTeamSearchDetail.path}`,
		element: <DashboardHeader />,
	},
	{
		path: `/${evoHuntPages.evohunt.subMenu.companyTeamSearchDetailParametersPage.path}`,
		element: <DashboardHeader />,
	},
	{
		path: `/${evoHuntPages.evohunt.subMenu.companyTeamStatisticPage.path}`,
		element: <DashboardHeader />,
	},
	{
		path: `/${evoHuntPages.evohunt.subMenu.evaluateCompetencesPage.path}`,
		element: <DashboardHeader />,
	},
	{
		path: `/${evoHuntPages.evohunt.subMenu.teamComponentCompetencePage.path}`,
		element: <DashboardHeader />,
	},
	{
		path: `/${evoHuntPages.evohunt.subMenu.teamComponentStatisticPage.path}`,
		element: <DashboardHeader />,
	},
	{ path: evoHuntPages.evohunt.subMenu.teamSearchResultsPage.path, element: <DashboardHeader /> },
	{
		path: `/${evoHuntPages.evohunt.subMenu.editCompanySearchResults.path}`,
		element: <DashboardHeader />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ricerca.subMenu.addStudyPage.path,
		element: <DashboardHeader />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ricerca.subMenu.addCompetencePage.path,
		element: <DashboardHeader />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ricerca.subMenu.addExperiencePage.path,
		element: <DashboardHeader />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ricerca.subMenu.addLanguagePage.path,
		element: <DashboardHeader />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.ricerca.subMenu.addSoftSkillPage.path,
		element: <DashboardHeader />,
	},
	{ path: evoHuntPages.evohunt.subMenu.queryDetail.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.companyMap.path, element: <DashboardHeader /> },
	{
		path: evoHuntPages.evohunt.subMenu.companyMapDistributionDetail.path,
		element: <DashboardHeader />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.companyMapEmployeesPage.path,
		element: <DashboardHeader />,
	},
	{ path: evoHuntPages.evohunt.subMenu.userDetailPage.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.startupStatisticsPage.path, element: <DashboardHeader /> },
	{
		path: evoHuntPages.evohunt.subMenu.ideatoreStatisticsPage.path,
		element: <DashboardHeader />,
	},
	{
		path: evoHuntPages.evohunt.subMenu.candidatureTestVideoEducational.subMenu.testVideo.subMenu
			.testResults.path,
		element: <DashboardHeader />,
	},
	{ path: evoHuntPages.evohunt.subMenu.advisorDetail.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.datiReferenteDetail.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.linkAziendaliDetail.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.companyAwards.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.companySectors.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.companyLocations.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.companyImages.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.userDetail.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.userStatisticsSkills.path, element: <DashboardHeader /> },
	{ path: evoHuntPages.evohunt.subMenu.userAssessmentSkills.path, element: <DashboardHeader /> },
];

export default headers;
